import React from 'react'
import { Title } from './Title'
import mailbox from '../Assets/images/mailbox.png'
import registerbox from '../Assets/images/registerbox.png'
import garagebox from '../Assets/images/garagebox.png'
import chatbox from '../Assets/images/chatbox.png'
import rewardbox from '../Assets/images/rewardbox.png'
export const OurWorkProcess = () => {
    const cardContentArr = [
        {
            cardNumber: 1,
            cardTitle: 'Receive Your Membership Invite From Your Dealer',
            cardnumber: ', 01.',
            cardimg: mailbox
        },
        {
            cardNumber: 2,
            cardTitle: 'Register Your Account',
            cardnumber: ', 02.',
            cardimg: registerbox
        },
        {
            cardNumber: 3,
            cardTitle: 'Add Vehicles to Your Garage',
            cardnumber: ', 03.',
            cardimg: garagebox
        },
        {
            cardNumber: 4,
            cardTitle: 'Communicate Directly With Your Dealership Team',
            cardnumber: ', 04.',
            cardimg: chatbox
        },
        {
            cardNumber: 5,
            cardTitle: 'Earn Redeemable Reward Points',
            cardnumber: ', 05.',
            cardimg: rewardbox
        }
    ] 
  return (
    <div className='WorkProces' id='Work'>
        <div className='WorkProces_section_wrapper'>
            <div className='left'>
                <h2 className='banner__title strong m-b-20'>
                    <span>How it Works</span>
                </h2> 
                {/* <Para para='Give it the care & attention it deserves.' style='italic' size='xl'/> */}
            </div>
            <div className='right'>
            
                <div className='box__wrapper2  howitworkbox-wrapper straight nowrap'>
                    {
                        cardContentArr.map((card) => (
                            <div className='box__item' key={card.cardNumber}>
                                {/* <span>{card.cardnumber}</span> */}
                                <div className='box__item_Icon__wrapper'>
                                    <img src={card.cardimg} alt='BannerRightImage'/>
                                </div>
                                <Title Title={card.cardTitle} size='xsm'/>
                            </div>
                        ))
                    }
                </div>
            
            </div>
        </div>
    </div>
  )
}
