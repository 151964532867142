import React from 'react'
import { Button } from './Button'
import AppContext from '../AppContext';
import { useContext } from 'react';
import heroimg from '../Assets/images/bannerheroimg.png'
import { Para } from './Para'

function closesidepanel(){
  var element = document.querySelector(".navitem_wrapper");
  element.classList.remove("open");
}
export const Banner = (props) => {
  const myContext = useContext(AppContext);
  return (
    props.from === 'home' ?
      <div 
        onClick={() => {
          var elements = document.querySelector(".nav__dropdown");
          elements.classList.remove("active");
          // myContext.setloadingstate(false);
        }}
        className='banner__wrapper flex dir-row' 
        id={props.from}>
          <div className=''>
            <h2 className='banner__title strong m-b-20'>
                With The Help Of <br></br>
                Technology, Automotive <br></br>
                Retailers Can Now Create A <br></br>
                <span>Personalized Experience</span> <br></br> For Their Customers.
            </h2>
            <div className='flex space'>
                <Button title='Sign up Dealership' color='white' link='#Contact'/>
                <Button link='https://apps.apple.com/pk/app/ibizi/id1659590288' title='Download the App' color='transparent'/>
            </div>
          </div>
        
          <img className='heroimgwrapper' src={heroimg} alt='BannerRightImage'/>
          {/* <img className='textImage' src={textimg} alt='BannerRightImage'/> */}
      </div>
    :
    props.from === 'vehicle' ?
        <div className='banner__wrapper vehicle' onClick={() => {
          var elements = document.querySelector(".nav__dropdown");
          elements.classList.remove("active");
          // myContext.setloadingstate(false);
        }} id='vehicle'>
          <div className='left'>
            <h2 className='banner__title strong'>
                Find Your <span>Next Vehicles!</span> <br></br> View 
                Your<span> DealerShip's </span>Available  Inventory 
                at <br></br>your <span>fingertips! </span>
            </h2>
            <Para para='Search for your next new, used, or leased vehicle directly on your app from the comfort of your home.' style='normal' size='xl'/>
            
          </div>
          <div className='right'>
            {/* <img className='heroimgwrapper' src={vehiclebannerimg} alt='BannerRightImage'/> */}
            <div className='bannerbuttons' onClick={closesidepanel}>

                  <li className='nav_items act'  onClick={closesidepanel}>
                    <a href='#Home' onClick={() => {
                      myContext.setactivepagename('vehicles');
                      myContext.setstickynav(true);
                      // myContext.setloadingstate(false);
                    }}>
                      Vehicles
                    </a>
                  </li>

                  <li className='nav_items' onClick={closesidepanel}>
                    <a href='#Home' onClick={() => {
                      myContext.setactivepagename('services');
                      myContext.setstickynav(true);
                      // myContext.setloadingstate(false);
                    }}>
                      Services
                    </a>
                  </li>

                  <li className='nav_items' onClick={closesidepanel}>
                    <a href='#Home' onClick={() => {
                      myContext.setstickynav(true);
                      myContext.setactivepagename('protectionplan');
                      // myContext.setloadingstate(false);
                    }}>
                      Protection Plans
                    </a>
                  </li>

                  <li className='nav_items' onClick={closesidepanel}>
                    <a href='#Home' onClick={() => {
                      myContext.setstickynav(true);
                      myContext.setactivepagename('rewards');
                      // myContext.setloadingstate(false);
                    }}>
                      Rewards
                    </a>
                  </li>
            </div>
          </div>
          

          {/* <img className='textImage' src={textimg} alt='BannerRightImage'/> */}
        </div>
    :
    props.from === 'protectionplan' ?
      <div className='banner__wrapper protectionplan' onClick={() => {
        var elements = document.querySelector(".nav__dropdown");
        elements.classList.remove("active");
        // myContext.setloadingstate(false);
      }} id='protectionplan'>
        <div className='left'>
          <h2 className='banner__title strong'>
              Purchase <br></br><span>Protection Plans</span> <br></br>for your Vehicle
          </h2>
          {/* <Para para='Search for your next new, used or leased vehicle directly on your app from the comfort of your home. you can mark favorites to monitor when price change accur.' style='italic' size='md'/> */}
          {/* <div className='flex space'>
              <Button title='Sign up as a Dealership' color='white'/>
          </div> */}
        </div>
        <div className='right'>
          {/* <img className='heroimgwrapper' src={protectionplanbannerimg} alt='BannerRightImage'/> */}
          <div className='bannerbuttons' onClick={closesidepanel}>

                <li className='nav_items'  onClick={closesidepanel}>
                  <a href='#Home' onClick={() => {
                    myContext.setactivepagename('vehicles');
                    myContext.setstickynav(true);
                    // myContext.setloadingstate(false);
                  }}>
                    Vehicles
                  </a>
                </li>

                <li className='nav_items' onClick={closesidepanel}>
                  <a href='#Home' onClick={() => {
                    myContext.setactivepagename('services');
                    myContext.setstickynav(true);
                    // myContext.setloadingstate(false);
                  }}>
                    Services
                  </a>
                </li>

                <li className='nav_items act' onClick={closesidepanel}>
                  <a href='#Home' onClick={() => {
                    myContext.setstickynav(true);
                    myContext.setactivepagename('protectionplan');
                    // myContext.setloadingstate(false);
                  }}>
                    Protection Plans
                  </a>
                </li>

                <li className='nav_items' onClick={closesidepanel}>
                  <a href='#Home' onClick={() => {
                    myContext.setstickynav(true);
                    myContext.setactivepagename('rewards');
                    // myContext.setloadingstate(false);
                  }}>
                    Rewards
                  </a>
                </li>
          </div>
        </div>
        

        {/* <img className='textImage' src={textimg} alt='BannerRightImage'/> */}
      </div>
    :
    props.from === 'rewards' ?
      <div className='banner__wrapper rewards' onClick={() => {
        var elements = document.querySelector(".nav__dropdown");
        elements.classList.remove("active");
        // myContext.setloadingstate(false);
      }} id='rewards'>
        <div className='left'>
          <h2 className='banner__title strong'>
              IBIZI <br></br><span>Rewards</span> Program
          </h2>
          {/* <Para para='Search for your next new, used or leased vehicle directly on your app from the comfort of your home. you can mark favorites to monitor when price change accur.' style='italic' size='md'/>
          <div className='flex space'>
              <Button title='Sign up as a Dealership' color='white'/>
          </div> */}
        </div>
        <div className='right'>
          {/* <img className='heroimgwrapper' src={ibizirewardsbannerimg} alt='BannerRightImage'/> */}
          <div className='bannerbuttons' onClick={closesidepanel}>

                <li className='nav_items'  onClick={closesidepanel}>
                  <a href='#Home' onClick={() => {
                    myContext.setactivepagename('vehicles');
                    myContext.setstickynav(true);
                    // myContext.setloadingstate(false);
                  }}>
                    Vehicles
                  </a>
                </li>

                <li className='nav_items' onClick={closesidepanel}>
                  <a href='#Home' onClick={() => {
                    myContext.setactivepagename('services');
                    myContext.setstickynav(true);
                    // myContext.setloadingstate(false);
                  }}>
                    Services
                  </a>
                </li>

                <li className='nav_items' onClick={closesidepanel}>
                  <a href='#Home' onClick={() => {
                    myContext.setstickynav(true);
                    myContext.setactivepagename('protectionplan');
                    // myContext.setloadingstate(false);
                  }}>
                    Protection Plans
                  </a>
                </li>

                <li className='nav_items act' onClick={closesidepanel}>
                  <a href='#Home' onClick={() => {
                    myContext.setstickynav(true);
                    myContext.setactivepagename('rewards');
                    // myContext.setloadingstate(false);
                  }}>
                    Rewards
                  </a>
                </li>
          </div>
        </div>
        

        {/* <img className='textImage' src={textimg} alt='BannerRightImage'/> */}
      </div>

    :
    props.from === 'services' ?
        <div className='banner__wrapper services' onClick={() => {
          var elements = document.querySelector(".nav__dropdown");
          elements.classList.remove("active");
          // myContext.setloadingstate(false);
        }} id='services'>
          <div className='left'>
            <h2 className='banner__title strong'>
                <span>Schedule</span><br></br> and <span>Purchase</span> <br></br>your Service Items
            </h2>
            {/* <Para para='Search for your next new, used or leased vehicle directly on your app from the comfort of your home. you can mark favorites to monitor when price change accur.' style='italic' size='md'/> */}
            {/* <div className='flex space'>
                <Button title='Sign up as a Dealership' color='white'/>
            </div> */}
          </div>
          <div className='right'>
            {/* <img className='heroimgwrapper' src={servicesbannerimg} alt='BannerRightImage'/> */}
            <div className='bannerbuttons' onClick={closesidepanel}>

                  <li className='nav_items'  onClick={closesidepanel}>
                    <a href='#Home' onClick={() => {
                      myContext.setactivepagename('vehicles');
                      myContext.setstickynav(true);
                      // myContext.setloadingstate(false);
                    }}>
                      Vehicles
                    </a>
                  </li>

                  <li className='nav_items act' onClick={closesidepanel}>
                    <a href='#Home' onClick={() => {
                      myContext.setactivepagename('services');
                      myContext.setstickynav(true);
                      // myContext.setloadingstate(false);
                    }}>
                      Services
                    </a>
                  </li>

                  <li className='nav_items ' onClick={closesidepanel}>
                    <a href='#Home' onClick={() => {
                      myContext.setstickynav(true);
                      myContext.setactivepagename('protectionplan');
                      // myContext.setloadingstate(false);
                    }}>
                      Protection Plans
                    </a>
                  </li>

                  <li className='nav_items' onClick={closesidepanel}>
                    <a href='#Home' onClick={() => {
                      myContext.setstickynav(true);
                      myContext.setactivepagename('rewards');
                      // myContext.setloadingstate(false);
                    }}>
                      Rewards
                    </a>
                  </li>
            </div>
          </div>
          

          {/* <img className='textImage' src={textimg} alt='BannerRightImage'/> */}
        </div>
    :
    props.from === 'contact' ?
        <div className='banner__wrapper contact' onClick={() => {
          var elements = document.querySelector(".nav__dropdown");
          elements.classList.remove("active");
          // myContext.setloadingstate(false);
        }} id='contacts'>
          <div className='left'>
            <h2 className='banner__title strong'>
                Contact<br></br><span>IBIZI</span> Today
            </h2>
            {/* <Para para='Search for your next new, used or leased vehicle directly on your app from the comfort of your home. you can mark favorites to monitor when price change accur.' style='italic' size='md'/> */}
            {/* <div className='flex space'>
                <Button title='Sign up as a Dealership' color='white'/>
            </div> */}
          </div>
          <div className='right'>
            {/* <img className='heroimgwrapper' src={servicesbannerimg} alt='BannerRightImage'/> */}
            {/* <div className='bannerbuttons' onClick={closesidepanel}>

                  <li className='nav_items'  onClick={closesidepanel}>
                    <a href='#Home' onClick={() => {
                      myContext.setactivepagename('vehicles');
                      myContext.setstickynav(true);
                      // myContext.setloadingstate(false);
                    }}>
                      Vehicles
                    </a>
                  </li>

                  <li className='nav_items act' onClick={closesidepanel}>
                    <a href='#Home' onClick={() => {
                      myContext.setactivepagename('services');
                      myContext.setstickynav(true);
                      // myContext.setloadingstate(false);
                    }}>
                      Services
                    </a>
                  </li>

                  <li className='nav_items ' onClick={closesidepanel}>
                    <a href='#Home' onClick={() => {
                      myContext.setstickynav(true);
                      myContext.setactivepagename('protectionplan');
                      // myContext.setloadingstate(false);
                    }}>
                      Protection Plans
                    </a>
                  </li>

                  <li className='nav_items' onClick={closesidepanel}>
                    <a href='#Home' onClick={() => {
                      myContext.setstickynav(true);
                      myContext.setactivepagename('rewards');
                      // myContext.setloadingstate(false);
                    }}>
                      Rewards
                    </a>
                  </li>
            </div> */}
          </div>
          

          {/* <img className='textImage' src={textimg} alt='BannerRightImage'/> */}
        </div>
    :
    <h4>{props.from}</h4>
  )
}
