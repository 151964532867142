import { useState } from "react";
import emailjs from "@emailjs/browser";

const AccountDeletionForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    userId: "",
    accountId: "",
    reason: "",
  });

  const [focusedField, setFocusedField] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitMessage, setSubmitMessage] = useState("");

  const handleFocus = (field) => {
    setFocusedField(field);
  };

  const handleBlur = (field) => {
    if (formData[field] === "") {
      setFocusedField("");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Validation as the user types
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateField(name, value),
    }));
  };

  const validateField = (name, value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    switch (name) {
      case "name":
        if (!value) return "Please enter your name.";
        break;
      case "userId":
        if (!value) return "Please enter your email.";
        if (!emailPattern.test(value)) return "Please enter a valid email.";
        break;
      case "accountId":
        if (!value) return "Please enter the account email for deletion.";
        if (!emailPattern.test(value))
          return "Please enter a valid account email.";
        break;
      default:
        return "";
    }
    return "";
  };

  const validateForm = () => {
    const formErrors = {};
    Object.keys(formData).forEach((field) => {
      formErrors[field] = validateField(field, formData[field]);
    });
    setErrors(formErrors);
    return Object.keys(formErrors).every((field) => !formErrors[field]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSending(true);
      setSubmitMessage("");

      const templateParams = {
        name: formData.name,
        userId: formData.userId,
        accountId: formData.accountId,
        reason: formData.reason,
      };

      emailjs
        .send("service_bvjuj9m", "template_ziqsa24", templateParams, "XTUwMGWmmSiPehDCh")
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            setIsSending(false);
            setSubmitMessage("Request sent successfully.");
            setTimeout(() => {
                setSubmitMessage("");
              }, 2000);
            setFormData({
              name: "",
              userId: "",
              accountId: "",
              reason: "",
            });
          },
          (err) => {
            console.log("FAILED...", err);
            setIsSending(false);
            setSubmitMessage("Failed to send request. Please try again.");
            setTimeout(() => {
                setSubmitMessage("");
              }, 2000);

          }
        );
    }
  };

  return (
    <section className="account-deletion-form-section">
      <h2 className="banner__title strong">
        <span>Account Deletion</span> Form
      </h2>
      <p>
        By submitting your request, you understand and agree that your account
        will be deleted and there will be no way to restore it after deletion is
        completed.
      </p>
      <div className="form_group__wrapper">
        <form onSubmit={handleSubmit} className="deletion-form">
          <div className="form_group">
            <label
              className={`main-text-field-label ${
                focusedField === "name" || formData.name ? "focused" : ""
              }`}
              htmlFor="name"
            >
              User Name
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onFocus={() => handleFocus("name")}
              onBlur={() => handleBlur("name")}
              onChange={handleChange}
            />
            {errors.name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.name}
              </div>
            )}
          </div>

          <div className="form_group">
            <label
              className={`main-text-field-label ${
                focusedField === "userId" || formData.userId ? "focused" : ""
              }`}
              htmlFor="userId"
            >
              User Email
            </label>
            <input
              type="email"
              name="userId"
              value={formData.userId}
              onFocus={() => handleFocus("userId")}
              onBlur={() => handleBlur("userId")}
              onChange={handleChange}
            />
            {errors.userId && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.userId}
              </div>
            )}
          </div>

          <div className="form_group">
            <label
              className={`main-text-field-label ${
                focusedField === "accountId" || formData.accountId
                  ? "focused"
                  : ""
              }`}
              htmlFor="accountId"
            >
              Requested Account Email for Deletion
            </label>
            <input
              type="email"
              name="accountId"
              value={formData.accountId}
              onFocus={() => handleFocus("accountId")}
              onBlur={() => handleBlur("accountId")}
              onChange={handleChange}
            />
            {errors.accountId && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.accountId}
              </div>
            )}
          </div>

          <div className="form_group">
            <label
              className={`main-text-field-label ${
                focusedField === "reason" || formData.reason ? "focused" : ""
              }`}
              htmlFor="reason"
            >
              Reason (optional)
            </label>
            <textarea
              name="reason"
              rows="4"
              value={formData.reason}
              onFocus={() => handleFocus("reason")}
              onBlur={() => handleBlur("reason")}
              onChange={handleChange}
            ></textarea>
          </div>

          <div className="form_button_wrapper msggroup">
            {submitMessage && <p className="form-message-status">{submitMessage}</p>}

            <div className="theme__button black">
              <input
                type="submit"
                value={isSending ? "Submitting..." : "Submit"}
                disabled={isSending}
              />
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default AccountDeletionForm;
