import React from 'react'
import { Button } from './Button'
import { Para } from './Para'
import { Title } from './Title'

export const LikeOilForEngine = () => {
  return (
    <div className='Like__Oil__For__Engine' id='about'>
        <div className='Like__Oil__For__Engine_section_wrapper'>
            <div className='flex'>
                <div className='left'>
                    {/* <Title Title='Like Oil For Engine..' size='lg'/> */}
                    <h2 className='banner__title strong m-b-20'>
                        IBIZI Makes<br></br>
                        <span>Communication</span> Easier!
                    </h2>  
                    <Para 
                    para='
                    The IBIZI App easily connects vehicle owners directly
                    to their dealership team. We provide a direct line
                    of communication to make life easier for asking
                    questions, scheduling service appointments,
                    and even shopping for new, leased, or previously
                    owned vehicles.
                    ' style='normal' size='xl'/>
                </div>
                <div className='right'>
                    <div className='box__wrpper'>
                        <Title Title='For Dealerships' size='mdl'/>
                        <Para para='Partner with IBIZI to stay connected with
                        your customers.' size='xl'/>
                        <Button title='Schedule A Demo' link="#Contact" color='black'/>
                        {/* <Title Title='REGISTER YOUR BUSINESS' size='sm'/> */}
                    </div>
                    <div className='box__wrpper'>
                        <Title Title='For Vehicle Owners' size='mdl'/>
                        <Para para='Having a vehicle is time-consuming & can be stressful. Not only when
                        you’re looking for a vehicle but also when you’re maintaining that
                        vehicle. IBIZI will help remind you when your next service is, reward
                        you points to help you save money on your next service or part, and
                        most importantly provide you with a direct link to your dealership.' size='xl'/>
                        <Button  link='https://apps.apple.com/pk/app/ibizi/id1659590288'  title='Download The App' color='black'/>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}
