import AppContext from '../AppContext';
import { useContext } from 'react';
import React, { useState, useEffect, useRef } from "react";
import Video from '../Assets/images/videoForSplash.mp4'

export const Loader = () => {

  const myContext = useContext(AppContext);
  const [Loaderstate, setLoaderstate] = useState(true);
    
  setTimeout(() => {
    setLoaderstate(false);
  }, "2000")
  setTimeout(() => {
    myContext.setloadingstate(true);
  }, "1500")
  const videoEl = useRef(null);

  const attemptPlay = () => {
      videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
      console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
      attemptPlay();
  }, []);

  return (
    <div className={`loading__wrapper ${Loaderstate ? 'active' : 'hide'}`}>
         {/* <img className='' src={heroimg} alt='BannerRightImage'/>  */}
         <video
            style={{ maxWidth: "100%", width: "800px", margin: "0 auto" }}
            playsInline
            loop
            muted
            alt="All the devices"
            src={Video}
            ref={videoEl}
            />
    </div>
  )
}
