import React from 'react'
import { Para } from './Para'
import { Title } from './Title'
import prodctservice from '../Assets/images/productservice.png'
import prodctpp from '../Assets/images/productPP.png'
import prodctvehicle from '../Assets/images/productvehicles.png'
import prodctreward from '../Assets/images/productrewards.png'
import AppContext from '../AppContext';
import { useContext } from 'react';

export const Product = () => {
    const myContext = useContext(AppContext);
  return (
    <div className='ibiz_product'>
        <div className='ibiz_product_section_wrapper'>
            <div className='flex'>
                <div className='left'>
                    {/* <Title Title='Like Oil For Engine..' size='lg'/> */}
                    <h2 className='banner__title strong m-b-20'>
                        IBIZI<br></br>
                        <span>Products</span>
                    </h2>  
                    <Para 
                    para='
                        We at IBIZI want to make your automotive
                        shopping and maintenance easier. You can
                        communicate with your dealership, book a
                        service appointment, and even find your
                        next vehicle.
                    ' style='normal' size='xl'/>
                </div>
                <div className='right'>
                    <a href='#Home' className='product_box__wrpper'  onClick={() => {
                            myContext.setactivepagename('services');
                            myContext.setstickynav(true);
                            // myContext.setloadingstate(false);
                        }}>
                        <div className='product_box_content_wrpper'>
                            <Title Title='Services' size='mdl'/>
                            <Para para='Schedule and Purchase Your Service Items' style='normal' size='sm'/>
                        </div>
                        <img src={prodctservice} alt='BannerRightImage'/>
                    </a>
                    <a href='#Home' className='product_box__wrpper' onClick={() => {
                            myContext.setactivepagename('protectionplan');
                            myContext.setstickynav(true);
                            // myContext.setloadingstate(false);
                        }}>
                        <div className='product_box_content_wrpper'>
                            <Title Title='Protection Plan' size='mdl'/>  
                            <Para para='Purchase Protection Plans for your Vehicle' style='normal' size='sm'/>
                        </div>
                        <img src={prodctpp} alt='BannerRightImage'/>
                    </a>
                    <a href='#Home' className='product_box__wrpper' onClick={() => {
                            myContext.setactivepagename('vehicles');
                            myContext.setstickynav(true);
                            // myContext.setloadingstate(false);
                        }}>
                        <div className='product_box_content_wrpper'>
                            <Title Title='Vehicles' size='mdl'/> 
                            <Para para='Find Your Next Vehicle' style='normal' size='sm'/>
                        </div>
                        <img src={prodctvehicle} alt='BannerRightImage'/>
                    </a>
                    <a href='#Home' className='product_box__wrpper' onClick={() => {
                            myContext.setactivepagename('rewards');
                            myContext.setstickynav(true);
                            // myContext.setloadingstate(false);
                        }}>
                        <div className='product_box_content_wrpper'>
                            <Title Title='Rewards' size='mdl'/> 
                            <Para para='Earn IBIZI Rewards Points in the App' style='normal' size='sm'/>
                        </div>
                        <img src={prodctreward} alt='BannerRightImage'/>
                    </a>
                </div>
            </div>
        </div>
    </div>
  )
}
