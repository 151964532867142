import React from 'react'
import { Banner } from './Banner'
import { Contact } from './Contact'
import { Features } from './Features'
import { Footer } from './Footer'
import { LikeOilForEngine } from './LikeOilForEngine'
import { Navbar } from './Navbar'
import { OurWorkProcess } from './OurWorkProcess'
import AppContext from '../AppContext';
import { useContext } from 'react';
import servicebnnerimg from '../Assets/images/img1.png'
import earnpointimg from '../Assets/images/img2.png'
import iconoil from '../Assets/images/oilchanges.png'
import tirerotation from '../Assets/images/tirerotations.png'
import wheelalignment from '../Assets/images/wheelalignments.png'
import tires from '../Assets/images/tries.png'
import brakepads from '../Assets/images/brakepads.png'
import batteries from '../Assets/images/batteries.png'
import airfilters from '../Assets/images/airfilters.png'
import wiperblades from '../Assets/images/wiperblades.png'
import multipoints from '../Assets/images/inspections.png'
import arrow from '../Assets/images/up-arrows.png'
import favorite from '../Assets/images/img3.png'
import vehicleapperance from '../Assets/images/img4.png'
import pffe from '../Assets/images/img5.png'
import eirp from '../Assets/images/img7.png'
import withibizipoints from '../Assets/images/img6.png'


import { DownLoadAppScetion } from './DownLoadAppScetion'

import { Loader } from './Loader'
import { Para } from './Para'
import { Product } from './Product'
import { LifeCycle } from './LifeCycle'
import Tabslayout from './TabsLayout'

export const PrivacyPolicy = () => {
  const myContext = useContext(AppContext);

  const servicesModel = [
    {
      icon : iconoil,
      title : 'Oil Change'
    },
    {
      icon : tirerotation,
      title : 'Tire Rotations'
    },
    {
      icon : wheelalignment,
      title : 'Wheel Alignments'
    },
    {
      icon : tires,
      title : 'Tires'
    },
    {
      icon : brakepads,
      title : 'Brake Pads'
    },
    {
      icon : batteries,
      title : 'Batteries'
    },
    {
      icon : airfilters,
      title : 'Air Filters'
    },
    {
      icon : wiperblades,
      title : 'Wiper Blades'
    },
    {
      icon : multipoints,
      title : 'Inspections'
    }
    // {
    //   icon : otherimg,
    //   title : 'Other'
    // }
  ]

  return (
    <>
      <Navbar/>
      {
        myContext.loadingstate === false &&
        <Loader/>
      }
    <div class="banner__wrapper policy-page-banner flex dir-row" id="home">
        <div class="">
            <h1 class="banner__title strong "><span>Privacy Policy</span> </h1><div class="flex space"></div></div>
    </div>
      <div class="policy-page">
    <p>At IBIZI, we take your privacy seriously and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you use our services.</p>
    
    <h2 class="banner__title strong m-b-20"> <span>1. Information We Collect</span> </h2>
    <p>We may collect various types of information to provide and improve our services, including:</p>
    <ul>
        <li><strong>Personal Information:</strong> When you register, we collect personal details such as your name, email address, phone number, and other contact details.</li>
        <li><strong>Usage Data:</strong> We collect data on how you interact with our app, including features you use, actions you take, and device information such as IP addresses, browser type, and operating system.</li>
        <li><strong>Transaction Information:</strong> If your app includes transactions, we collect billing and payment information such as credit card details and billing addresses.</li>
        <li><strong>Location Data:</strong> We may collect precise location data if you grant us permission, which helps provide location-based services.</li>
    </ul>
    <h2 class="banner__title strong m-b-20"> <span>2. How We Use Your Information</span> </h2>

    <p>The information we collect is used to:</p>
    <ul>
        <li><strong>Provide and Manage Services:</strong> To deliver the features and services you sign up for and ensure smooth functionality.</li>
        <li><strong>Improve Our Platform:</strong> Analyze usage data to enhance user experience, troubleshoot issues, and make improvements to our app.</li>
        <li><strong>Communicate with You:</strong> Send important information such as account updates, security alerts, and marketing messages (where consented).</li>
        <li><strong>Security and Fraud Prevention:</strong> Protect our platform from unauthorized access, fraud, and other security threats.</li>
        <li><strong>Legal Obligations:</strong> Comply with legal obligations and resolve any disputes that may arise.</li>
    </ul>
    <h2 class="banner__title strong m-b-20"> <span>3. How We Share Your Information</span> </h2>

    <p>We do not sell or rent your personal information. However, we may share your information under specific circumstances, including:</p>
    <ul>
        <li><strong>With Service Providers:</strong> We may share data with trusted third-party providers who help us operate our platform and services (e.g., cloud storage, customer support).</li>
        <li><strong>For Legal Reasons:</strong> We may disclose your information if required by law or in response to legal proceedings, such as subpoenas or court orders.</li>
        <li><strong>Business Transfers:</strong> In case of mergers, acquisitions, or asset sales, your information may be transferred to the new owner as part of the business.</li>
    </ul>
    <h2 class="banner__title strong m-b-20"> <span>4. Data Security</span> </h2>

    <p>We implement industry-standard security measures to protect your personal information. This includes encryption, firewalls, and secure access protocols. However, no method of transmission or storage is 100% secure, so we cannot guarantee absolute security.</p>
    <h2 class="banner__title strong m-b-20"> <span>5. Your Rights and Choices</span> </h2>

    <p>You have the right to:</p>
    <ul>
        <li><strong>Access and Update Your Information:</strong> You can access and modify your personal information through your account settings.</li>
        <li><strong>Delete Your Information:</strong> You can request the deletion of your personal data by following the steps in our <a href="link to your account deletion page">Account Deletion Policy</a>.</li>
        <li><strong>Opt Out of Marketing:</strong> You can choose to stop receiving marketing communications by following the unsubscribe instructions included in our emails or contacting our support team.</li>
    </ul>
    <h2 class="banner__title strong m-b-20"> <span>6. Cookies and Tracking Technologies</span> </h2>

    <p>We use cookies and similar tracking technologies to enhance user experience, analyze usage trends, and deliver personalized content. You can control cookies through your browser settings, but please note that some features of the app may not function correctly if cookies are disabled.</p>
    <h2 class="banner__title strong m-b-20"> <span>7. Children’s Privacy</span> </h2>

    <p>Our services are not directed to individuals under the age of 13, and we do not knowingly collect personal information from children. If you are a parent or guardian and believe your child has provided us with personal data, please contact us so we can take steps to delete such information.</p>
    <h2 class="banner__title strong m-b-20"> <span>8. Changes to This Privacy Policy</span> </h2>

    <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the updated policy will include the revision date at the top. We encourage you to review this policy periodically to stay informed about how we are protecting your information.</p>
    <h2 class="banner__title strong m-b-20"> <span>9. Contact Us</span> </h2>

    <p>If you have any questions or concerns about this Privacy Policy or how your data is handled, please contact us at:</p>
    <p><strong>IBIZI</strong><br/>
    Email: <a href="mailto:info@ibizi.com">info@ibizi.com</a></p>
      </div>
      <Footer/>
      <a href='#Home' className='scrolltopicon'>
        <img src={arrow} loading="lazy"  alt='sterick'/>
      </a>
    </>
   
  )
}
