
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import NewVehicles from '../Assets/images/newtabimg.png'
import UsedVehicles from '../Assets/images/usedtabimg.png'
import LeasedVehicles from '../Assets/images/leasedtabimg.png'
import newvehiclescntnt from '../Assets/images/newvehiclescntnt.png'
import usedvehiclescntnt from '../Assets/images/usedvehiclescntnt.png'
import leasedvehiclescntnt from '../Assets/images/leasedvehiclescntnt.png'

import { Para } from './Para';

function Tabslayout() {
  return (
    <Tabs>
        <TabList>
            <Tab>
                <div className='img_wrapper'>
                    <img src={NewVehicles} alt='BannerRightImage'/>
                </div>
                <div className='tab_title'>New Vehicles</div>
            </Tab>
            <Tab>
                <div className='img_wrapper'>
                    <img src={UsedVehicles} alt='BannerRightImage'/>
                </div>
                <div className='tab_title'>Used Vehicles</div>
            </Tab>
            <Tab>
                <div className='img_wrapper'>
                    <img src={LeasedVehicles} alt='BannerRightImage'/>
                </div>
                <div className='tab_title'>Leased Vehicles</div>
            </Tab>
        </TabList>

        <TabPanel>
            <div className={`Common__Section protectionplan_futureexpenses`}>
                <div className='Common__Section_section_wrapper'>
                    <div className='flex'>
                        <div className='left'>
                            <h2 className='banner__title strong'>
                            New<br></br> 
                            <span>Vehicles</span>
                            </h2>
                            <Para para='Is it time for a new car, truck, or SUV?
                                You can search for vehicles by price,
                                make, model, and color.' style='normal' size='xl'/>
                        </div>
                        <div className='right'>
                            <img src={newvehiclescntnt} alt='BannerRightImage'/>
                        </div>
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel>
            <div className={`Common__Section protectionplan_futureexpenses`}>
                <div className='Common__Section_section_wrapper'>
                    <div className='flex'>
                        <div className='left'>
                            <h2 className='banner__title strong'>
                            Used<br></br> 
                            <span>Vehicles</span>
                            </h2>
                            <Para para='Find your perfect new-to-you
                                pre-owned vehicle within
                                the app.
                            ' style='normal' size='xl'/>
                        </div>
                        <div className='right'>
                            <img src={usedvehiclescntnt} alt='BannerRightImage'/>
                        </div>
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel>
            <div className={`Common__Section protectionplan_futureexpenses`}>
                <div className='Common__Section_section_wrapper'>
                    <div className='flex'>
                        <div className='left'>
                            <h2 className='banner__title strong'>
                            Leased<br></br> 
                            <span>Vehicles</span>
                            </h2>
                            <Para para='Prefer to lease versus buy, the
                            app has your dealership’s lease
                            deals within it also!
                            ' style='normal' size='xl'/>
                        </div>
                        <div className='right'>
                            <img src={leasedvehiclescntnt} alt='BannerRightImage'/>
                        </div>
                    </div>
                </div>
            </div>
        </TabPanel>
    </Tabs>
  );
}

export default Tabslayout;