import './App.css';
import { Landing } from './Components/Landing';
import React, {useState} from 'react';
import $ from 'jquery'
import { Loader } from './Components/Loader';
import AppContext from './AppContext.js';
import { PrivacyPolicy } from './Components/PrivacyPolicy.js';
import { AccountDeletionPolicy } from './Components/AccountDeletionPolicy.js';
import { Routes, Route } from "react-router-dom";

function App() {
  

  const [homepage, sethomepage] = useState(true);
  const [loadingstate, setloadingstate] = useState(true);
  const [vehiclestate, setvehiclestate] = useState(true);
  const [stickynav, setstickynav] = useState(false);
  const [activepagename, setactivepagename] = useState('home');

  
  const toggleSetting2 = () => {
    setvehiclestate(true);
    sethomepage(true);
    setloadingstate(true);
    setactivepagename('home');
    setstickynav(false);
  };
  

  const userSettings = {
    vehiclestate,
    homepage,
    loadingstate,
    activepagename,
    stickynav,
    sethomepage,
    setstickynav,
    setloadingstate,
    setactivepagename,
    setvehiclestate, 
    toggleSetting2
  };
  

  $(window).scroll(function() {    
    var scroll = $(window).scrollTop();
    if (scroll >= 100) { 
          $(".Sticky__Hero__Section").addClass("active");
          $(".navbar__wrapper").addClass("active");
        } else {
          $(".Sticky__Hero__Section").removeClass("active");
          $(".navbar__wrapper").removeClass("active");
        }
  });
  $(window).scroll(function() {    
    var scroll = $(window).scrollTop();
    if (scroll >= 300) { 
          $(".scrolltopicon").addClass("active");
        } else {
          $(".scrolltopicon").removeClass("active");
        }
  });
  
  return (
    <div className="Main__Landing__wrapper" id='Home'>
      <AppContext.Provider value={userSettings}>
        
        <>
           <Loader />
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy/> } />
              <Route path="/account-deletion-policy" element={<AccountDeletionPolicy />} /> 
            </Routes>
        </>
      </AppContext.Provider> 
    </div>
  );
}

export default App;
