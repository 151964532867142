import React from 'react'
import { Para } from './Para'
import { Title } from './Title'
import heroimg from '../Assets/images/featuresmob.png'
import heroicon1 from '../Assets/images/maintenance.png'
import heroicon5 from '../Assets/images/multicarmanagement.png'
import heroicon6 from '../Assets/images/customersupport.png'
import serviceappointmenticon from '../Assets/images/serviceappointmenticon.png'
import rewardsprogram from '../Assets/images/rewardsprogram.png'
import specials from '../Assets/images/specialsicon.png'

export const Features = () => {

    const cardContentArr = [
        {
            cardNumber: 1,
            cardImageUrl: heroicon6,
            cardTitle: 'Communication',
            cardDesc: 'With a built-in chat feature you can communicate with your Dealership.',
        },
        {
            cardNumber: 2,
            cardImageUrl: specials,
            cardTitle: 'Specials',
            cardDesc: 'Access Dealer specials on vehicles, protection plans, and service needs.',
        },
        {
            cardNumber: 3,
            cardImageUrl: rewardsprogram,
            cardTitle: 'Reward Program',
            cardDesc: 'Earn redeemable rewards.',
        },
        
        {
            cardNumber: 4,
            cardImageUrl: serviceappointmenticon,
            cardTitle: 'Service Appointments',
            cardDesc: 'Use the app to schedule your benefits and upcoming service appointments.',
        },
        {
            cardNumber: 5,
            cardImageUrl: heroicon5,
            cardTitle: 'Multi-Vehicle Management ',
            cardDesc: 'The app keeps your different vehicles on file in their own “garages.”',
            },
        {
            cardNumber: 6,
            cardImageUrl: heroicon1,
            cardTitle: 'Maintenance Reminder',
            cardDesc: 'The app reminds you of upcoming maintenance needs.',
        }
    ] 
  return (
    <div className='Features' id='Features'>
        <div className='Features_section_wrapper'>
            <div className='flex'>
                <div className='left'>
                    <h2 className='banner__title strong m-b-20'>
                        <span>Features</span>
                    </h2> 
                    <Para para='The App That Takes Away Your Stress and Gives You Back Your Time' style='normal' size='xl'/>
                    <img className='heroimgwrapper' src={heroimg} alt='BannerRightImage'/>
                </div>
                <div className='right'>
                    <div className='box__wrapper'>
                    {
                        cardContentArr.map((card) => (
                            <div className='box__item' key={card.cardNumber}>
                                <div className='box__item_Icon__wrapper'>
                                    <img src={card.cardImageUrl} alt='BannerRightImage'/>
                                </div>
                                <Title Title={card.cardTitle} size='sm'/>
                                <Para para={card.cardDesc} style='normal' size='sm'/>
                            </div>
                        ))
                    }
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
