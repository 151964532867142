import React from 'react'
import { Para } from './Para'
import { Title } from './Title'
import astore from '../Assets/images/astore.png'
import gplay from '../Assets/images/gplay.png'

export const DownLoadAppScetion = (props) => {
  return (
    <div className={`${props.sectionidentity}`}>
        <div className='downloadsection_section_wrapper right_style'>
            <div className='flex'>
                <div className='left'>
                    <Title Title={props.title} size='lg'/>
                    <Para para={props.para} style='italic' size='md'/>  
                </div>
                <div className='right'>
                    <a href='#'>
                        <img src={gplay} alt='BannerRightImage'/>
                    </a>
                    <a href='https://apps.apple.com/pk/app/ibizi/id1659590288'>
                        <img className='' src={astore} alt='BannerRightImage'/>
                    </a>
                </div>
            </div>
        </div>
    </div>
  )
}
