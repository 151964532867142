import React from 'react'
import { Para } from './Para'
import sterick from '../Assets/images/stericklg.png'

export const LifeCycle = () => {
  return (
    <div className='LifeCycle'>
        <div className='LifeCycle_section_wrapper'>
            <div className='left'>
                    <h2 className='banner__title strong'>
                        Extend Your <br></br>
                        <span>Customer Lifecycle!</span>
                    </h2> 
                
                <Para para='Hop on the IBIZI ride and start your journey towards exponential growth!' style='normal' size='xl'/>
            </div>
            <div className='right'>
                <div className='bullets'>
                    <div className='sterick_wrapper'>
                        <img src={sterick} alt='sterick'/>
                    </div>
                    <Para para='Multiply your reach and increase your competitive edge to propel your business.' style='normal' size='md'/>
                </div>
                <div className='bullets'>
                    <div className='sterick_wrapper'>
                        <img src={sterick} alt='sterick'/>
                    </div>
                    <Para para='Communicate directly with your customers to provide customer service, offer specials, and ultimately build loyalty.' style='normal' size='md'/>
                </div>
                <div className='bullets'>
                    <div className='sterick_wrapper'>
                        <img src={sterick} alt='sterick'/>
                    </div>
                    <Para para='Plan for future staff and supply needs! Manage load through scheduled customer visits.' style='normal' size='md'/>
                </div>
            </div>
        </div>
    </div>
  )
}
