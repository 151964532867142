import React from 'react'
import { EmailForm } from './Emailform'
import { Para } from './Para'


export const Contact = (props) => {
  return (
    props.from === "contctpage" ?
    <div className='Contact' id='Contact'>
          <div className='Contact_section_wrapper'>
              <div className='flex'>
                  <div className='left'>
                    <h2 className='banner__title strong'>
                        <span>Need help?</span> Get in touch
                    </h2> 
                      <Para para='Want to know more about us or our services? Get In Touch with us by sending us a email ' style='normal' size='xl'/>
                  </div>
                  <div className='right'>
                    
                    <div className='form_group__wrapper'>
                      <EmailForm from="contactform"/>
                    </div>
                  </div>
              </div>
          </div>
      </div>
    :
    <div className='Contact' id='Contact'>
        <div className='Contact_section_wrapper'>
            <div className='flex'>
                <div className='left'>
                  <h2 className='banner__title strong'>
                      <span>Schedule</span> a Demo!
                  </h2> 
                    <Para para='Don’t hesitate to offer your customers the latest way to communicate with you. We will be in touch with you very soon!' style='normal' size='xl'/>
                </div>
                <div className='right'>
                  
                  <div className='form_group__wrapper'>
                    <EmailForm from="homeform"/>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}
